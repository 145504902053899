<template>
  <div v-loading="loading" class="table-area">
    <el-row class="table-top">
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading">User Details</h4>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <div class="search-wrapper"></div>
      </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="btn-wrapper">
          <!-- <el-button type="primary" @click="handleEditParent(viewData.id)">Edit</el-button>
          <el-button type="primary" @click="resetTransactionPassword" >Reset Transaction Password</el-button>
          <el-button type="primary" @click="changePassword">Change Password</el-button> -->
          <el-button @click="viewDetails" type="primary" style="width:100%;">View Details</el-button>
        </div>
      </el-col>
    </el-row>


      <div>
        <div v-if="showViewDetails" class="table_wrapper">
          <span style="color: #fff; margin-left: 8px;">Personal Details</span>
          <br /><br />
          <el-card class="setting_wrapper">
        <div class="table_wrapper">
          <table class="table" border="1" width="100%" cellspacing="0">
         
            <tbody>
              <tr>
                <th style="width:200px;">
                  <p>User Name:</p>
                </th>
                <td style="width:300px;">
                  <p>{{ viewData.username }}</p>
                </td>
                <th style="width:200px;">
                  <p>Name:</p>
                </th>
                <td>
                  <p>{{ viewData.name }}</p>
                </td>
              </tr>
            </tbody>
           
          </table>
        </div>
          </el-card>
          
          <br />
          
          <span style="color: #fff; margin-left: 8px;">Config</span>
          <br /><br />
          <el-card class="setting_wrapper">
        <div class="table_wrapper">
           <table class="table" border="1" width="100%" cellspacing="0">
            <tbody>
              <tr>
                <th>
                  <p>
                    Profit / loss Share to broker
                  </p>
                </th>
                <td>
                  <p>
                    {{
                      config.config_profit_loss_share_to_broker
                        ? "Yes"
                        : "No"
                    }} ({{ config.config_profit_loss_share_to_broker_value ? config.config_profit_loss_share_to_broker_value : 0 }})
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>
                    Brokerage Share to broker
                  </p>
                </th>
                <td>
                  <p>
                    {{
                      config.config_brokerage_share_to_broker
                        ? "Yes"
                        : "No"
                    }} ({{ config.config_brokerage_share_to_broker_value ? config.config_brokerage_share_to_broker_value : 0 }})
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Create Client :</p>
                </th>
                <td>
                  <p>
                    {{
                      config.config_create_client ? "Yes" : "No"
                    }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
          </el-card>
         

        </div>
        <br>
         <span style="text-align:left;color:#fff; margin-left: 12px;">Trading Clients</span>
         <div v-loading="loading" class="table-area" style="margin-top:70px;">
            <Table
              tableKey="tradingClients"
              :tableConfig="configTwo"
              :tableData="listData"
              :tableQuery="listQuery"
              :tableActionVisibility="false"
              :tableActionViewVisibility="true"
              :tableActionDollarVisibility="true"
              :tableActionShowVisibility="true"
              :tableActionEditVisibility="tableActionEditVisibility"
              :tableLoadingStatus.sync="loading"
              :tablePagination="tablePagination"
              @edit="handleEdit($event)"
              @view="handleViewDetails($event)"
              @dollar="handleDollar($event)"
              @pagination="handlePagination()"
            />
          </div>


      </div>
    


    <AddEditDialog
      :dialog-visible.sync="dialogVisible"
      :dialog-type="dialogType"
      :form-data="formData"
      :form-errors.sync="formErrors"
      @getChildFormData="handleChildFormData($event)"
      @childClose="handleChildClose()"
    />

     <PasswordEditDialog
      :dialog-visible.sync="dialogVisiblePasswordEdit"
      :dialog-type="dialogTypePasswordEdit"
      :form-data="formData"
      :form-errors.sync="formErrors"
      @getChildFormData="handleUpdatePassword($event)"
      @childClose="handleChildClosePasswordEdit()"
    />

  </div>
</template>

<script>
import { edit, passwordUpdate, resettransactionpassword, fetchListClients } from "@/api/trading-broker";
import Table from "@/components/Table/Table.vue";
import { fetchList as fetchFundList } from "@/api/user-fund";
import getRoleSlug from "@/store/app.js";
import AddEditDialog from "@/views/trader-funds/components/AddEditDialog.vue";
import { store } from "@/api/trader-fund";
import PasswordEditDialog from "@/views/trading-clients/components/PasswordEditDialog.vue";





export default {
  name: "PendingOrders",
  components: {
    Table,
    AddEditDialog,
    PasswordEditDialog
  },
  data() {
    return {
      loading: false,
      tableActionEditVisibility:true,
      viewData: {},
      equity: {},
      mcx: {},
      config: {},
      showViewDetails: false,
      configTwo: [
        {
          label: "ID",
          prop: "userid",
          width: "",
          className: "blueFont",
        },
        {
          label: "Name",
          prop: "name",
          width: "180",
          className: "blueFont",
        },
        {
          label: "Username",
          prop: "username",
          width: "200",
          popover: false,
          className: "blueFont",
        },
        {
          label: "Ledger Balance",
          prop: "ledger_balance",
          width: "180",
          className: "blueFont",
        },
        {
          label: "Gross PL",
          prop: "gross_pl",
          width: "120",
          className: "blueFont",
        },
        {
          label: "Brokerage",
          prop: "brokerage",
          width: "180",
          className: "blueFont",
        },
        {
          label: "Net PL",
          prop: "net_pl",
          width: "150",
          className: "blueFont",
        },
        {
          label: "Demo Account?",
          prop: "demo_account",
          width: "180",
          className: "blueFont",
        },
        {
          label: "Account Status",
          prop: "status",
          width: "200",
          className: "blueFont",
        },
      ],
      listQuery: {
        page: 1,
        limit: 20,
        search: "",
        search_type: "",
        searchJoin: "or",
        orderBy: "created_at",
        sortedBy: "desc",
        id: this.$route.params.id
      },
      listData: {
        data: {
          item: [],
        },
      },
      tablePagination: {},
      listDataTemp: null,
      funds: {},
      dialogVisible: false,
      dialogType: "create",
      formData: {},
      formErrors: [],
      dialogVisiblePasswordEdit: false,
      dialogTypePasswordEdit: "create",
    };
  },
  computed: {},
  created() {
    this.id = this.$route.params.id;
    console.log(this.$route.params.id);
    this.getPendingOrderDetails();
    this.getList();
    this.getFund();
    if(getRoleSlug.state.roleSlug == 'superadmin') {
      this.tableActionEditVisibility = false;
    }

  },
  methods: {
    handleEditParent(id) {
      return this.$router.push("/"+ getRoleSlug.state.roleSlug +"/users/user-edit/" + id );
    },
    handlePagination() {
      this.getList();
    },
    handleEdit(id) {
      console.log(id)
      return this.$router.push(
        "/" + getRoleSlug.state.roleSlug + "/trading-clients/trading-clients-edit/" + id
      );
    },
    handleViewDetails(data) {
      console.log(data);
      if(getRoleSlug.state.roleSlug == 'superadmin') {
          return this.$router.push("/super-admin/trading-clients/trading-clients-view/" + data.id
      );
        } else {
          return this.$router.push( "/" + getRoleSlug.state.roleSlug + "/trading-clients/trading-clients-view/" + data.id
      );
        }
    },
    getList() {
      this.loading = true;
      fetchListClients(this.listQuery).then((response) => {
        this.listDataTemp = this.listData.data.item = response.data.data.data;
        this.tablePagination = response.data.data.meta;
        this.loading = false;
      });
    },
    getFund() {
      this.loading = true;
      let querry = {
        type: 'broker',
        userId: this.$route.params.id
      }
      fetchFundList(querry).then((response) => {
        this.funds = response.data.data.data;
        console.log(response.data.data.data);
        // this.tablePagination = response.data.data.meta;
        this.loading = false;
      });
    },
    viewDetails() {
      this.showViewDetails = !this.showViewDetails
    },
    // //////////////////
    // GET LIST DATA
    // //////////////////
    // handlePagination() {
    //   this.getPendingOrderDetails();
    // },
    handleSearch() {
      this.listQuery.page = 1;
      this.getPendingOrderDetails();
    },
    getPendingOrderDetails() {
      this.loading = true;
      edit(this.$route.params.id).then((response) => {
        this.viewData = response.data.data;
        this.config = response.data.data.config;
        this.equity = response.data.data.equity;
        this.mcx = response.data.data.mcx;
        console.log(this.viewData);
        console.log(this.config);
        this.loading = false;
      });
    },
    handleDollar(data) {
      console.log(data);
      this.formData.user_id = data.id;
      this.formErrors = [];
      this.dialogVisible = true;
      this.dialogType = "create";
    },
    handleChildClose() {
      this.dialogVisible = false;
    },
    // //////////////////
    // EMIT METHODS
    // //////////////////
    handleChildFormData(data) {
      this.handleStore(data);
    },
    handleStore(data) {
      this.loading = true;
      console.log(data);
      this.dialogVisible = false;
      this.getList();
      store(data)
        .then((response) => {
          if (response.data.success === true) {
            this.dialogVisible = false;
            this.flashSuccess(response.data.message);
            this.getList();
            this.loading = false;
          } else {
            this.flashError(response.data.message);
            this.formErrors = this.validationErrors(response.data.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changePassword() {
      this.formData = {};
      this.formErrors = [];
      this.dialogVisiblePasswordEdit = true;
      this.dialogTypePasswordEdit = "create";

    },
    handleChildClosePasswordEdit() {
      this.dialogVisiblePasswordEdit = false;

    },
    handleUpdatePassword(newPassword) {
      console.log(newPassword);
      let dataPost = {
        id : this.$route.params.id,
        new_password : newPassword
      }
      console.log(dataPost);
      passwordUpdate(dataPost)
        .then((response) => {
          if (response.data.success === true) {
            this.flashSuccess(response.data.message);
            this.loading = false;
            this.dialogVisiblePasswordEdit = false;
            location.reload()
            // this.getList();
          } else {
            this.formErrors = this.validationErrors(
              response.data.message
            );
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });

    },
    resetTransactionPassword() {
        this.$confirm(
        "Transaction password will be reset to 12345. User will be forced to change password & transaction password.. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
          let dataPost = {
            id : this.$route.params.id,
          }
        resettransactionpassword(dataPost)
          .then((response) => {
            if (response.data.success === true) {
              this.flashSuccess(response.data.message);
              this.loading = false;
              this.dialogVisiblePasswordEdit = false;
              location.reload()
              // this.getList();
            } else {
              this.formErrors = this.validationErrors(
                response.data.message
              );
            }
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });

      });





    }
  },
};
</script>
